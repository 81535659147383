import React from "react"
import Layout from "../components/layout"

import SEO from "../components/SEO"

const CodeOfConduct = () => {
  return (
    <Layout>
      <SEO title="Code of Conduct" />
      <div>This is the Code of Conduct page</div>
    </Layout>
  )
}

export default CodeOfConduct
